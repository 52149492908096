.notif {
  transition: transform 1s ease;
}

.notif.animated {
  transform: translateY(200px);
}

@media screen and (max-width: 768px) {
  .small-zoom {
    zoom: 0.7;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
