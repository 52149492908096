@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
}

* {
  box-sizing: border-box;
  font-family: sans-serif;

  scrollbar-width: none;
  /* ::-webkit-scrollbar {
    display: none;
  } */
}

.App {
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  position: relative;
}
